<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    props: {
        to: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const route = useRoute();
        const isActive = (computed(() => route.path === `/dashboard/${props.to}`) || computed(() => route.path === `/dashboard/`));
        return { isActive };
    }
}
</script>

<template>
    <router-link :to="'/dashboard/' + to" class="navButton navContainer clickable">
        <div class="btnLayout" :class="{ active: isActive }">
                <div class="iconBox">
                    <i class="icon" :class="icon"/>
                </div>
                <div class="btnContent">
                    <div class="btnName">
                        <div class="btnNameChild"><slot /></div>
                    </div>
                </div>
        </div>
    </router-link>
</template>

<style scoped>
.iconBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.icon {
    width: 20px;
    height: 20px;
    overflow: hidden;
}

.btnContent {
    min-width: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.btnContent, .btnNameChild {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.btnName {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btnNameChild {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.navButton {
    position: relative;
    margin-right: 8px;
    margin-left: 8px;
}

.navContainer {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    padding: 1px 0;
    border-radius: 4px;
    -webkit-transition: none;
    transition: none;
    color: #8e9297;
}

.btnLayout:hover {
    color: #dcddde;
    opacity: 1;
    background-color: rgba(79,84,92,0.16);
}

.btnLayout.active {
    background-color: rgba(79,84,92,0.24);
    color: #fff;
}

.btnLayout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    height: 38px;
    padding: 0 8px;
}
</style>