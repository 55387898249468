<template>
<title>Lithium Dashboard</title>
<div class="dashMount">
  <div class="dashApp">
    <div class="app">
      <div class="background"/>
      <div class="layer1 layer2">
        <div class="layer3 baseLayer">
          <div class="oContainer">
            <div class="base">
              <div class="content">
                <Sidebar />
                <div class="containerView">
                  <router-view />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Sidebar from '../components/sidebar/Sidebar.vue'
const axios = require('axios');

export default {
  name: 'Dashboard',
  props: {
    msg: String
  },
  components: {
      Sidebar
  },
}
</script>

<style scoped>
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.containerView {
  background-color: #36393f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashMount {
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashMount, body {
    background-color: #202225;
    text-rendering: optimizeLegibility;
}

.dashMount, body, html {
  height: 100%;
  width: 100%;
}

.dashApp {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  z-index: auto;
}

.app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #202225;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.background {
  background-color: #202225;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.layer1 {
  position: relative;
  overflow: hidden;
}

.layer2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}

.layer3.baseLayer {
  background-color: transparent;
}

.oContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.base {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
</style>
