<script>
import SidebarLink from './SidebarLink';
import axios from 'axios';

export default {
  props: {},
  components: { SidebarLink },
  data () {
    return {
      discord_avatar: ``,
      discord_id: ``,
      discord_username: ``,
      discord_discriminator: ``,
    }
  },
  methods: {
      setUserData() {
          console.log(`setUserData`);
          axios({
            method: 'get',
            url: `${process.env.VUE_APP_APIURL}/user/data`,
            withCredentials: true
          }).then((res) => {
              console.log(res);
              if (res != (null || false)) {
                    this.discord_avatar = res.data.avatar;
                    this.discord_id = res.data.id;
                    this.discord_username = res.data.username;
                    this.discord_discriminator = res.data.discriminator;
              } else {
                  this.$router.push('/auth');
              }
          });
      },
      logout() {
          axios({
              method: 'post',
              url: `${process.env.VUE_APP_APIURL}/logout`,
              withCredentials: true
          }).then((res) => {
            this.$cookie.removeCookie("user_session");
            this.$router.push({ name: 'Login' });
          }).catch((err) => {
            console.error(err);
            this.$router.push({ name: 'Login' });
          })
      }
  },
  beforeMount() {
      this.setUserData()
  },
}
</script>

<template>
  <div class="sidebar hasNotice" style="height: 100vh;">
      <div class="logoBar"><h1 style="color: white; font-size: 20px;">ProtonRP</h1></div>
      <nav class="navigationSelect">
          <div class="sidebarContent">
              <div class="spacer"/>
              <SidebarLink icon="fas fa-tachometer-alt" to="home">Dashboard</SidebarLink>
              <h2 class="HeaderContainer container2">
                  <span class="headerText">NAVIGATION</span>
              </h2>
              <SidebarLink icon="fas fa-traffic-light" to="queue">Queue Management</SidebarLink>
              <SidebarLink icon="fas fa-users" to="players">Player Management</SidebarLink>
              <SidebarLink icon="fas fa-arrow-circle-up" to="leaderboard">Leaderboard</SidebarLink>
              <SidebarLink icon="fas fa-chart-pie" to="diagnostics">Diagnostics</SidebarLink>
              <SidebarLink icon="fas fa-book-open" to="logs">Lithium Logbook</SidebarLink>
              <h2 class="HeaderContainer container2">
                  <span class="headerText">CRITICAL ACCESS</span>
              </h2>
              <SidebarLink icon="fas fa-sliders-h" to="settings">Dashboard Settings</SidebarLink>
              <SidebarLink icon="fas fa-user-shield" to="staff">Authorization Control</SidebarLink>
          </div>
      </nav>
      <section class="userPanel">
          <div class="userPanelWrapper"/>
            <div class="userPanelContainer">
                <div class="userPanelAvatarWrapper">
                <div class="userPanelAvatar avatarWrapper" style="width: 32px; height: 32px;">
                    <foreignObject x='0' y='0' width='32' height='32'>
                        <img class="rounded-corners" v-bind:src="`https://cdn.discordapp.com/avatars/`+discord_id+`/`+discord_avatar+`.png`" alt="{{ discord_username }}#{{ discord_discriminator }}" style="width: 32px; height: 32px; border-radius: 50%;">
                    </foreignObject>
                </div>
                </div>
                <div class="nameTag">
                    <div class="usernameContainer">
                        <div class="usernameText">{{ discord_username }}</div>
                    </div>
                    <div class="discriminatorText">#{{ discord_discriminator }}</div>
                </div>
                <div class="logoutBtnBox">
                    <button type="button" class="logoutBtn" v-on:click="logout">
                        <i class="logoutIcon fas fa-sign-out-alt"/>
                    </button> 
                </div>
            </div>
      </section>
  </div>
</template>

<style scoped>
.logoBar {
    z-index: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0 10px;
    height: 48px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-shadow: 0 1px 0 rgba(4,4,5,0.2),0 1.5px 0 rgba(6,6,7,0.05),0 2px 0 rgba(4,4,5,0.05);
    box-shadow: 0 1px 0 rgba(4,4,5,0.2),0 1.5px 0 rgba(6,6,7,0.05),0 2px 0 rgba(4,4,5,0.05);
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    justify-content: center;
}

.logoutBtn {
    cursor: pointer;
    line-height: 0;
    width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    color: #b9bbbe;
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
}

.logoutBtn:hover {
    color: #dcddde;
    background-color: rgba(79,84,92,0.32);
}

.logoutIcon {
    width: 18px;
    height: 18px;
}

.logoutBtnBox {
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

div, h2, a {
    outline: 0;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
    text-decoration: none;
}

section {
    display: block;
}

.sidebar {
    flex-direction: column;
    min-height: 0;
    width: 220px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background: #2f3136;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    overflow: hidden;
}

.sidebar .hasNotice {
    border-radius: 0;
}

.navigationSelect {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #2f3136;
}

.sidebarContent {
    position: relative;
}

.HeaderContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 18px 8px 4px 18px;
    height: 40px;
}

.container2 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .25px;
    font-family: Ginto,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 600;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    color: #8e9297;
}

.headerText {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spacer {
    height: 8px;
}

.logo {
    height: 40px;
    text-align: center;
    width: 100%;
    background-color: #2f3136;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.userPanel {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #292b2f;
    z-index: 1;
}

.userPanelWrapper {
    position: relative;
    overflow: visible;
}

.userPanelContainer, .userPanelContainer .userPanelAvatar {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.userPanelContainer {
    height: 52px;
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 1px;
}

.userPanelAvatarWrapper {
    margin-right: 8px;
}

.avatarWrapper {
    position: relative;
    border-radius: 50%;
}

.userPanelContainer, .userPanelAvatar {
    cursor: default;
}

.nameTag {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 4px;
    min-width: 0;
}

.usernameContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    color: #dcddde;
}

.usernameText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
}

.discriminatorText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #b9bbbe;
    line-height: 16px;
    font-size: 12px;
}
</style>